@import './tests';

.custom-navbar {
  background-color: var(--background);
  margin: calc(8 * #{$padding-variable}) calc(40 * #{$padding-variable});
  padding: 0;
  border-radius: 12px;

  img {
    filter: brightness(0);
    transition: filter 0.3s ease;

    [data-theme="dark"] & {
      filter: brightness(0) invert(1);
    }
  }

  @media (max-width: 1199px) {
    margin-left: calc(20 * #{$padding-variable});
    margin-right: calc(20 * #{$padding-variable});
    max-width: 100%;
  }

  @media (max-width: 767px) {
    margin-left: calc(10 * #{$padding-variable});
    margin-right: calc(10 * #{$padding-variable});
  }

  .container-fluid {
    padding: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
  }

  .nav-logo {
    margin-right: calc(20 * #{$padding-variable});
    height: calc(11 * #{$padding-variable});

    filter: brightness(0) invert(0);
    [data-theme="dark"] & {
      filter: brightness(0) invert(1);
    }
  }
  .dropdown-toggle::after {
    content: '';
    display: inline-block;
    margin-left: 8px; 
    width: 0;
    height: 0;
    border-top: 4.5px solid transparent; 
    border-bottom: 4.5px solid transparent; 
    border-left: 4.5px solid var(--text-dark-200);
    transition: transform 0.3s ease, top 0.3s ease; 
    position: relative;
    top: 3px; 
  }
  
  /* When dropdown is open, rotate the arrow */
  .dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(90deg);
  }

  @media (min-width: 1199px) {
    .navbar-nav {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .nav-link {
        padding: 0 calc(4 * #{$padding-variable});
        color: var(--text-dark-200);
        text-decoration: none;
        font-size: 14px;

        &:hover {
          color: var(--button-hover);
        }

        &.active {
          font-weight: bold;
          color: var(--button-hover);
          text-decoration: underline;
        }
      }
    }
    
    .dropdown-menu.show {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 8px;
      border-radius: 20px;
      box-shadow: 0 4px 6px var(--shadow-color);
      background-color: var(--background);
      min-width: 160px;
      margin-top: 10px;

      .dropdown-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 8px 12px;
        color: var(--text-dark-200);
        font-size: 13px;
        border-bottom: none;
        text-decoration: none;
        border-radius: 12px;
        margin: 0px auto;
        box-sizing: border-box;
        width: calc(100% - 16px);
        overflow: hidden;
        cursor: pointer;
        transition: background-color 0.3s ease, border 0.3s ease;

        &:hover {
          background-color: var(--background-200);
          border-radius: 12px;
          border: solid 1px var(--background-300);
        }

        &.active-option {
          font-weight: bold;
          text-decoration: underline;
          color: var(--button-hover);
          // background-color: var(--background-200);
        }

        img {
          margin-right: 8px;
        }

        span {
          flex: 1;
          text-align: left;
        }
      }

      .nested-dropdown .dropdown-item {
        justify-content: center;
        text-align: center;
        padding: 8px 0;
      }
    }

    .nested-dropdown {
      .dropdown-option {
        cursor: pointer;
        transition: background-color 0.3s ease;

        &.active-option {
          font-weight: bold;
          text-decoration: underline;
          color: var(--text-dark-200) !important;
        }
      }
    }
  }

  .icon {
    width: 18px;
    height: 18px;
    display: inline-block;
  }

  .dropdown-arrow {
    margin-left: 10px;
    display: inline-block;
    transition: transform 0.3s ease, opacity 0.3s ease, filter 0.3s ease;
    opacity: 0; 
    pointer-events: none; 
    filter: none;
  
    &.hover-visible {
      opacity: 1; 
      color: var(--text-dark-200);
    }
  
    &.visible {
      opacity: 1; 
      pointer-events: auto; 
    }
  
    &.open {
      transform: rotate(0deg); 
    }
  
    &.closed {
      transform: rotate(0deg); 
    }
  }
  
  @media (max-width: 1199px) { // telefona režīms
    .navbar-nav {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .nav-link {
        padding: calc(2 * #{$padding-variable}) 0;
        text-align: center;
        color: var(--text-dark-200);
        text-decoration: none;
      }
    }
  
    .dropdown-menu.show {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border: none;
      text-align: center;
      background-color: var(--background);
  
      .dropdown-item {
        display: flex;
        border: none;
        align-items: center;
        justify-content: center;
        padding: calc(2 * #{$padding-variable}) calc(3 * #{$padding-variable});
        color: var(--text-dark-300);
        font-size: 14px;
        text-decoration: none;
        position: relative;
        font-weight: 400; 
  
        &::before {
          content: "•"; 
          font-size: 20px;
          color: var(--primary-color); 
          margin-right: 8px; 
        }
  
        &:hover {
          background-color: var(--button-hover);
          color: var(--background);
        }
  
        img {
          margin-right: 8px;
          width: 20px;
          height: 20px;
        }
  
        span {
          margin: 0 auto;
        }
  
        /* Aktīvā opcija */
        &.active-option {
          font-weight: 900; 
          color: var(--text-dark-200); 
          position: relative;
        }
      }
    }
  
    .theme-toggle-btn {
      background: none;
      border: 1px solid var(--border-color);
      border-radius: 50%;
      padding: 0.5rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
  
      &:hover {
        background-color: var(--button-hover);
        color: var(--background);
        transform: scale(1.1);
      }
  
      svg {
        color: var(--text-dark-200);
        transition: transform 0.3s ease;
  
        &:hover {
          transform: rotate(45deg);
        }
      }
    }
  }
  
  .horizontal-divider {
    display: none;
  }
}


body {
  margin: 0;
  padding: 0;
  background: var(--background);
  overflow-x: hidden;
}
