$font-family-base: 'Roboto Slab', serif;

$background-light: #FFFF;  

$primary-100: #0077B6;
$primary-200: #56a6e9;
$primary-300: #c3ffff;

$accent-100: #00B4D8;
$accent-200: #005777;

$text-dark-100: #F1F1F1;
$text-dark-200: #E0E0E0;
$text-dark-300: #B8B8B8;

$text-light-100: #1A1A1A;
$text-light-200: #303030;
$text-light-300: #434343;

$bg-dark-100: #1A1A1A;
$bg-dark-200: #292929;
$bg-dark-300: #404040;
$bg-dark-400: #5D5D5D;

$bg-light-100: #F1F1F1;
$bg-light-200: #DCDCDC;
$bg-light-300: #C9C9C9;
$bg-light-400: #B7B7B7;

$success: #498A47;
$success-light: #6ABF6C; 
$success-dark: #3E7D32; 
$success-border: #3B6A4E; 

$warning: #C77A0F;
$danger: #F44336;
$danger-light: #f86c62;
$info: #C3FFFF;

// Iconas stils
$icon-color: #c7ddeb;
$icon-hover-color: #3cafd9;
$icon-size: 17px;
//telegram
$telegram-blue: #24A1DE;

$padding-variable: 4px;
