@import './tests';

#home-div {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 30vh);//118
  width: 100%;
  background-color: var(--background); 
  color: var(--text-dark-100); 
}

.main-page {
  padding: 0;
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;

  .row {
    width: 100%;
  }
}

.left-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 250px;
}

.text-container {
  width: 100%;
}

.text-container p {
  margin: 0;
  text-align: left;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: flex-start; 
}

#main-button {
  padding: 8px 15px;
  border-radius: 10px;
  background-color: var(--button-bg); 
  color: var(--text-light-100); 
  border: none;
  display: flex;
  align-items: center; 
  gap: 2px; 
  font-size: 14px; 
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--telegram-blue); 
  }

  .button-icon {
    width: 16px; 
    height: 16px; 
    fill: var(--text-light-100); 
  }
}

#main-text1 {
  display: flex;
  align-items: center; 
  font-size: 16px; 
  font-weight: bold; 
  color: var(--text-dark-100); 
  margin-bottom: 20px;

  &::before {
    content: '';
    display: inline-block;
    width: 40px; 
    height: 2px; 
    background-color: var(--text-dark-100); 
    margin-right: 10px; 
  }
}

#main-text2 {
  color: var(--text-dark-200); 
  font-size: 45px;
  margin-bottom: 24px;
  line-height: 1.4; 
  max-width: 800px; 
  text-align: left; 
  word-wrap: break-word; 
}

#main-text3 {
  color: var(--text-dark-200); 
  font-weight: 400;
  font-size: 18px;
  text-align: left; 
  max-width: 300px; 
  margin-bottom: 32px;
  word-wrap: break-word;
}

/* Responsivity for medium screens */
@media (max-width: 1200px) {
  .left-column {
    padding-left: 20%; 
  }

  #main-text2 {
    font-size: 40px;
    max-width: 600px;
  }

  #main-text3 {
    font-size: 16px;
    max-width: 250px;
  }
}

@media (max-width: 767px) {
  #home-div {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  #home-div {
    height: calc(100vh - 50vh); 
  }

  .main-page {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    text-align: center;
  }

  .left-column {
    align-items: center;
    padding: 0;
    text-align: center;
  }

  #main-text1 {
    font-size: 16px;
    justify-content: center;
    text-align: center;
    margin-bottom: 25px;
  }

  /* Noņem līniju ::before */
  #main-text1::before {
    content: none;
  }

  #main-text2 {
    padding: 0%;
    font-size: 30px;
    max-width: 80%;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  #main-text3 {
    font-size: 14px;
    max-width: 80%;
    line-height: 1.6;
    word-wrap: break-word;
    margin-bottom: 30px;
  }

  .text-container p{
    margin: 0;
    text-align: center;
  }
  #main-button {
    font-size: 14px;
    padding: 10px 20px;
    justify-content: center;
    align-self: center;
    max-width: 200px;
  }

  .button-container {
    justify-content: center;
    display: flex;
    width: 100%;
  }

  .text-container {
    width: 100%;
    max-width: 90%;
    text-align: center;
  }
}
@media (max-width: 480px) { 
  #home-div {
    height: calc(118vh - 75vh); 
  }
}
@media (max-width: 390px) { 
  #home-div {
    height: calc(118vh - 65vh);
  }
}
