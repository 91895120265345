@import './variables.scss';

#admin-console-container {
    padding: 0 20%;

    @media (max-width: 992px){
        padding: 0 5%;
    }

    .console-table {
        --bs-table-bg: transparent;
        --bs-table-color: $text-dark-100;

        color: $text-dark-100;

        margin: 0 0 40%;
        padding: 0;

        @media (max-width: 992px) {
            font-size: 12px;
        }
        @media (max-width: 576px) {
            font-size: 10px;
        }
        
    }

    .table-group-divider {
        border-color: $text-light-300;
    }

    .user-info {
        font-weight: 300;
        white-space: normal;
        word-wrap: break-word;
    }

    th, td {
        height: 80px; /* Ensure the height matches your buttons */
        vertical-align: middle; /* Vertically center the content */

    }

    .custom-button-delete, .custom-button-demote, .custom-button-promote {
        width: 40px;
        height: 40px;
        background-color: $bg-dark-400;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: none;
        line-height: 0; /* Normalize line height to avoid extra height */
        margin: 0; /* Ensure no extra margins */

        @media (max-width: 992px) {
            width: 30px; /* Adjust the size of the icon as needed */
            height: 30px; /* Adjust the size of the icon as needed */
        }
    
        .button-icon {
            width: 24px; /* Adjust the size of the icon as needed */
            height: 24px; /* Adjust the size of the icon as needed */

            @media (max-width: 992px) {
                width: 16px; /* Adjust the size of the icon as needed */
                height: 16px; /* Adjust the size of the icon as needed */
            }
        }
    }

    .custom-button-delete{
        &:hover {
            background-color: $danger;
        }
    }

    .custom-button-demote{
        &:hover {
            background-color: $warning;
        }
    }

    .custom-button-promote{
        &:hover {
            background-color: $success;
        }
    }

    .user-actions {
        display: flex;
        align-items: center; /* Ensures the buttons are vertically centered */
        justify-content: space-evenly;

        @media (max-width: 992px) {
            flex-direction: column;
            gap: 5px;
        }
    }
}
