#filter-page{
    align-items: center;
    min-height: 100vh!important;
    min-height: 250px;
    background-color: #0D0D0D;
    padding-top: 15px;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    padding: 12px 0;
    text-align: center;
    position: relative;
}
/* Atpakaļ poga */
.back-icon {
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.2s ease-in-out;

    &:hover {
        opacity: 0.7;
    }
}

/* Lielais virsraksts "Filters" */
.title {
    font-size: 22px;
    font-weight: 700;
    color: #E9E8E8;
    margin: 0;
}
// ----------------------------------------------------------------
/* Saglabā aktīvo un neaktīvo filtru fiksēto pozīciju */
.filters-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 450px;
    min-height: 100px; /* Nodrošina, ka sadaļa paliek savā vietā */
}

/* Tukša vieta, ja nav filtru */
.empty-placeholder {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #768C9E;
    font-size: 14px;
}

/* Aktīvo un neaktīvo filtru sadaļu nosaukumi (atstarpes un līnijas) */
.section-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #768C9E;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0;
}

.section-title::before {
    content: "";
    flex: 1;
    border-bottom: 1px solid #768C9E;
    margin: 0 10px 0 0; 
}

.section-title::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #768C9E;
    margin: 0 0 0 10px; 
}
// ----------------------------------------------------------------
/*  Filtru konteiners */
.filter-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 92%;
    max-width: 450px;
}

/* Neaktīvie filtri - samazināta caurspīdība */
.filter-item.inactive {
    opacity: 0.5;
}

.filter-item {
    background-color: #17212B;
    padding: 16px 22px;
    margin-bottom: 12px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
    width: 46px; 
    height: 46px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    }

    &:hover {
        background-color: #2B5278;
    }
}
// filtr nosaukums
    .filter-name{
        font-size: 18px; 
        font-weight: 400;
        color: #E9E8E8;
        margin-left: 8px;
    }

/* Pievienot filtra poga */
.btn-new-container {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.add-filter-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #17212B;
    border: none;
    border-radius: 15px;

    img {
        width: 27px;
        height: 27px;
        
    }

    &:hover {
        background-color: #2B5278;
    }
}

@media (max-width: 768px) {
    .title {
        font-size: 30px;
    }

    .filter-item {
        padding: 8px 10px;
        font-size: 20px;
        height: 60px;
        border-radius: 20px;
    }

    .add-filter-btn {
        padding: 12px 20px;
    }
}
