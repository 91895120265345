
.main-page-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: flex-start;
    background-color: #0D0D0D;
    padding-top: 15px;

/* Valodu izvēlne - optimizēta lasāmībai */
.language-selector {
    display: flex;
    padding-top: 40px;
    gap: 35px; 
    margin-bottom: 25px; 
}

.language-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    img {
        border-radius: 70px;
        transition: box-shadow 0.3s ease-in-out;
    }

    span {
        margin-top: 10px;
        font-size: 18px; 
        color: #E9E8E8;
        font-weight: 700;
    }

    &:hover {
        transform: scale(1.1);
    }

    &.active img {
        box-shadow: 0 0 15px 4px rgba(255, 255, 255, 0.5);
        border: 2px solid #FFFFFF;
        width: 64px;
        height: 64px;
        border-radius: 35px;
    }
    
}

/* Pogu sadaļa */
.button-section {
    display: flex;
    flex-direction: column;
    gap: 18px; 
    margin-top: 20px;
    width: 92%;
    max-width: 450px; 
}
// filtru span texts
.filters-button > span {
    font-weight: 700;
}
.menu-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 22px;
    background-color: #17212B;
    color: #E9E8E8;
    font-size: 18px; 
    font-weight: 400;
    border: none;
    border-radius: 22px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;

    span {
        margin-left: 8px; 
    }

    img {
        width: 36px; 
        height: 36px;
        margin-left: auto;
    }

    &:hover {
        background-color: #2B5278;
    }

    /* Opacity for disabled buttons  pašlaik noņemu nost piekļūvi pilnīmā visiem kam ir disabled*/
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;

        &:hover,
        &:active {
            background-color: #17212B; /* remove hover effect */
        }
    }

}

/* Sarkanā poga "Report a Problem" */
.report-button {
    color: #EC3942;
    background-color: #17212B;

    &:hover {
        background-color: rgba(231, 76, 60, 0.2);
    }
}

@media (max-width: 768px) {

    .language-selector {
        gap: 30px;
    }

    .language-option img {
        width: 64px;
        height: 64px;
    }

    .language-option span {
        font-size: 16px;
    }

    .button-container {
        gap: 12px;
    }

    .menu-button {
        padding: 8px 10px;
        font-size: 20px;
        height: 60px;
        border-radius: 20px;

        img {
            width: 46px;
            height: 46px;
        }
    }
}
}