@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

$font-family-base: 'Roboto Slab', serif;

$primary-100:#0077B6;
$primary-200:#56a6e9;
$primary-300:#c3ffff;

$accent-100:#00B4D8;
$accent-200:#005777;

$text-dark-100:#F1F1F1;
$text-dark-200:#E0E0E0;
$text-dark-300:#B8B8B8;

$text-light-100:#1A1A1A;
$text-light-200:#303030;
$text-light-300:#434343;

$bg-dark-100:#1A1A1A;
$bg-dark-200:#292929;
$bg-dark-300:#404040;
$bg-dark-400:#5D5D5D;

$bg-light-100:#F1F1F1;
$bg-light-200:#DCDCDC;
$bg-light-300:#C9C9C9;
$bg-light-400:#B7B7B7;

$success:#498A47;
$warning:#C77A0F;
$danger:#F44336;
$info:#C3FFFF;

@import "~bootstrap/scss/bootstrap";