#filter-page-container {
    align-items: center;
    min-height: 110vh;
    height: auto;
    background-color: #0D0D0D;
    padding-top: 15px;
    padding-bottom: 15px;

    .header-container {
        display: flex;
        justify-content: flex-end; 
        align-items: center;
        padding: 10px 0px 10px 0px;
        position: relative;

        .back-icon {
          width: 50px;
          height: 50px;
          cursor: pointer;
          position: absolute;
          left: 0px;
          top: 50%;
          transform: translateY(-50%);
          transition: opacity 0.2s ease-in-out;
      
          &:hover {
              opacity: 0.7;
          }
      }
  //------------------------------------Switch poga ar tekstu------------------------------------------------------------ 
        .filter-status {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 8px 15px;
          border-radius: 20px;
          font-weight: bold;
          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
          cursor: pointer;
          user-select: none;
      
          span {
              font-weight: 400;
              font-size: 15px;
              color: #FFFFFF;
              white-space: nowrap;
              display: flex;
              align-items: center;
          }
      
          .form-check-input {
              appearance: none;
              width: 45px;
              height: 18px;
              background-color: #B1B1B2;
              border-radius: 11px;
              position: relative;
              cursor: pointer;
              border: none;
              transition: background-color 0.3s ease-in-out, box-shadow 0.2s ease-in-out;
      
              &::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: -2px;
                  width: 24px;
                  height: 24px;
                  background-color: #5C5C5C;
                  border-radius: 50%;
                  transform: translateY(-50%);
                  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
              }
      
              &:checked {
                  background-color: #74CA79; // Zaļš fons aktīvam statusam
                  box-shadow: 0 0 5px rgba(0, 128, 0, 0.4);
              }
      
              &:checked::before {
                  background-color: #69A26D; // Baltāka poga
                  transform: translate(26px, -50%);
              }
      
              &:focus {
                  outline: none;
                  box-shadow: 0 0 3px rgba(0, 128, 0, 0.5);
              }
      
              &:disabled {
                  cursor: not-allowed;
                  background-color: #D3D3D3;
              }
          }
      }
      
      //------------------------------------Switch ar filteru izvēliem------------------------------------------------------------
      
    }
// ------------------------------------------------------------------------------------------------
.filters-form {
    background: #17212B;
    padding: 15px;
    margin-top: 10px;
    border-radius: 20px;
// ---------Filtra nosaukums ar līniju-------------
    .filter-name-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 10px;
    
        .filter-name-title {
            font-size: 20px;
            font-weight: 400;
            color: #FFFFFF;
            position: relative;
            display: inline-block;
            text-align: center;
            cursor: text;
    
            &::after {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%); 
                bottom: 3px;
                width: 100%;
                height: 1px;
                background-color: #FFFFFF;
            }
        }
    
        .filter-name-input {
            font-size: 20px;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            background: transparent;
            border: none;
            border-bottom: 1px solid #FFFFFF; // Apakšējā līnija kā vizuālā atdalīšana
            outline: none;
            width: auto;
            min-width: 50px;
            max-width: 100%;
        }
    }
// ---------------Alert message ---------------------
    .alert {
      font-size: 16px;
      font-weight: 500;
      padding: 12px 15px;
      border-radius: 6px;
      text-align: center;
      width: 100%;
      max-width: 500px;
      margin: 10px auto; 
      transition: all 0.3s ease-in-out;
  
      &.alert-danger {
          background-color: rgba(220, 53, 69, 0.9); 
          color: white;
          border: 1px solid rgba(200, 35, 51, 1);
          box-shadow: 0px 4px 8px rgba(220, 53, 69, 0.3);
      }
  
      &.fade-out {
          opacity: 0;
          transform: translateY(-10px);
      }
  }
  
// ---------------------------------Dropdowna izvēles active teksta krāsa  bet esošie dropdawn elementi paliek tādi kadi ir ----
    .custom-dropdown {
      color: #768C9E; 
  
      &:focus {
        outline: none;
        border: 1px solid #3D87F5;
      }
      &:active {
          color: #768C9E;
      }
  
      &:valid {
        color: darken(#FFFFFF, 20%);
      }
  
      option {
          color: #768C9E; 
      }
  
      option:checked {
        color: #FFFFFF; 
      }
  }
  // ------------------------------------------------------------------------------------------------
    
// ------------------------filtra nosaukums ar liniju beigas ----------------------------------------
    .dropdown-wrapper {
        position: relative;
        display: flex;
        align-items: center;
    
        .custom-dropdown {
            width: 100%;
            padding-right: 40px; 
            appearance: none; 
            background-image: none; 
        }
    
        .custom-dropdown-icon {
            position: absolute;
            right: 15px; 
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            pointer-events: none;
            transition: transform 0.2s ease-in-out;
        }
    
        .custom-dropdown:focus + .custom-dropdown-icon {
            transform: translateY(-50%) rotate(180deg);
        }
    }
    
    .form-control, .form-select {
        background-color: #0E1621;
        border-radius: 8px;
        padding: 8px;
        border: solid 1px #0E1621;
        color: #768C9E;
        font-size: 16px;
    }
// ---------------------------Dropdown icona un  form -colrol-------------------------------------
    .input-container {
      position: relative;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
  
      label {
        font-size: 16px;
        font-weight: bold;
        text-align: start;
        color: #FFFFFF;
        margin-bottom: 5px;
      }

      .input-group {
        display: flex;
        align-items: flex-end; 
        justify-content: center;
        gap: 5px; // Atstarpe starp laukiem un "-"
    
        .input-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start; 
          flex: 1; 
    
          label {
            font-size: 16px;
            font-weight: bold;
            text-align: start;
            color: white;
            margin-bottom: 5px;
          }
    
          input {
            background: #0E1621;
            border: none;
            border-radius: 10px;
            font-size: 16px;
            color: darken(#FFFFFF, 20%);
            text-align: left;
            padding: 10px;
            width: 100%;
    
            &:focus {
              outline: none;
              border: 1px solid #3D87F5;
            }
    
            &::placeholder {
              color: #768C9E;
              opacity: 1;
            }
          }
        }
    
        .symbol-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px; // Nodrošina, ka "-" simbols vienmēr būs centrēts
        }
    
        .input-group-text {
          background: transparent;
          border: none;
          color: white;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 5px;
          padding: 5px 10px;
          text-align: center;
        }
      }
    }
  
    // Car Model Field with "+" button
    .car-model-field {
      position: relative;
      display: flex;
      align-items: center;
      background: #0E1621;
      border-radius: 10px;
      padding: 10px;
      min-height: 40px;
      width: 100%;
      cursor: pointer;
  
      .selected-models-container {
        flex: 1;
        color: white;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
  
      .placeholder-text {
        color: #768C9E;
      }
  
      .add-icon-container {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
  
      .add-icon {
        width: 18px;
        height: 18px;
        filter: brightness(0.8);
        transition: filter 0.2s ease-in-out;
  
        &:hover {
          filter: brightness(1);
        }
      }
    }
  
    .selected-models-container {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
    }
  
    .selected-model {
      display: flex;
      align-items: center;
      background-color: #0E1621;
      border: 1px solid #768C9E;
      border-radius: 10px;
      padding: 2px 4px 2px 8px;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.3s ease, border-color 0.3s ease;
      height: 30px;
  
      &:hover {
        background-color: #25303E;
        border-color: #768C9E;
      }
  
      span {
        color: darken(#FFFFFF, 20%);
      }
  
      .remove-btn {
        background: none;
        border: none;
        color: #E9E8E8;
        cursor: pointer;
        transition: color 0.3s ease;
  
        &:hover {
          color: #EC3942;
        }
      }
    }
  // -----------------Car Model Field with "+" button beigas-------------------------------------

  .info-icon {
    position: relative;
    top: -2px;
    opacity: 0.6 !important;
  } 

  // -----------------toltipe iconas atrašanās vieta-------------------------------------
  .details-section {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center; // Centrē visu saturu horizontāli
    text-align: center;

    .details-toggle {
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
        position: relative; 

        span {
            display: inline-block;
            white-space: nowrap;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 5px;
                width: 100%;
                height: 1px;
                background-color: #FFFFFF;
            }
        }

        .details-toggle-icon {
            filter: invert(1); // Padara attēlu baltu
            transition: transform 0.2s ease-in-out, filter 0.2s ease-in-out;

            &:hover {
                filter: invert(1) brightness(1.2); // Padara ikonu gaišāku hover laikā
                transform: scale(1.1); // Nedaudz palielina ikonu
            }
        }
    }

    .details-content {
        margin-top: 20px;
    }
}

    .multi-select {
      background: #1a1e25;
      padding: 5px;
      border-radius: 5px;
      display: flex;
      flex-wrap: wrap;
  
      .selected-model {
        background: #37414d;
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin: 5px;
        font-size: 14px;
  
        button {
          background: transparent;
          border: none;
          color: #EC3942;
          font-size: 16px;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
  
    .add-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
      
        .add-button {
          background: transparent; 
          border: none;
          cursor: pointer;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.2s ease-in-out;
      
          &:hover {
            transform: scale(1.1);
          }
      
          .add-icon {
            width: 24px; 
            height: 24px;
            filter: brightness(0) invert(1); // Saglabā ikonai krāsu efektu
            transition: transform 0.2s ease-in-out;
      
            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }

    //   dropdown saraksts zem fielda  
      .dropdown-list {
        position: absolute; 
        top: 100%; 
        left: 0; 
        z-index: 1050; 
        background-color: #0E1621; 
        border: 1px solid #768C9E; 
        border-radius: 10px 0px 0px 10px;
        max-height: 200px;
        overflow-y: auto;
        padding: 8px; 
        width: 100%; 
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    }
    
    .dropdown-overlay {
        z-index: 9999;
    }
    
    .dropdown-item {
        padding: 8px;
        color: #FFFFFF;
        cursor: pointer;
        text-align: left;
        border-bottom: 1px solid #768C9E;
        font-size: 14px;
    }
    
    .dropdown-item:hover {
        background-color: #25303E;
    }
    
    .dropdown-item:last-child {
        border-bottom: none;
    }
    // ----------------dropdown ------------------------------
  
    .button-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .btn-save {
          background-color: #2F6EA5;
          border-color: #2F6EA5;
          color: white;
          font-size: 16px;
          font-weight: 700;
          padding: 10px 20px;
          width: 100%;
          gap: 5px;
          height: 40px;
          border-radius: 8px;
          align-items: center;
          justify-content: center;
          transition: background 0.3s ease;
  
          &:hover {
              background-color: darken(#2F6EA5, 10%);
          }
  
          .btn-icon {
              width: 22px;
              height: 22px;
          }
      }
  
      .btn-delete {
          background-color: #EC3942;
          border-color: #EC3942;
          width: 80px;
          height: 40px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          transition: background 0.3s ease;
  
          &:hover {
              background-color: darken(#EC3942, 10%);
          }
  
          .btn-icon {
              width: 25px;
              height: 25px;
          }
      }
  }
  
  // -----------------button-row beigas-------------------------------------
}
}  
// -------------
/* Blūra efekts modālajam fona pārklājumam */
.modal-backdrop {
  backdrop-filter: blur(30px);
  background-color: rgba(0, 0, 0, 0.9) !important; 
}

/* Modālā loga satura konteiners */
.custom-modal .modal-content {
  border-radius: 20px;
  border: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Ēna */
  background-color: #182533; 
}

/* Modālā loga galvene */
.custom-modal .modal-header {
  border-bottom: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

/* Filtra nosaukuma konteiners */
.custom-modal .filter-name-container {
  display: flex; 
  align-items: center; /* Vertikāli centrē elementus */
  gap: 5px;
  justify-content: center;
}

/* Filtra nosaukuma virsraksts */
.custom-modal .filter-name-title {
  font-size: 20px;
  font-weight: 700;
  color: #FFFFFF; 
  margin: 0;
}

/* Apstiprinājuma teksta stils */
.custom-modal .modal-title {
  font-size: 20px;
  color: #FFFFFF; 
  margin: 0;
}

/* Modālā loga saturs */
.custom-modal .modal-body {
  padding: 0 25px;
  font-size: 16px; 
  font-weight: 400;
  color: #FFFFFF; 
  text-align: left; 
}

.highlight-text {
  font-weight: 400;
  color: #EC3942; 
}

/* Modālā loga kājene */
.custom-modal .modal-footer {
  border-top: none;
  justify-content: flex-end;
  padding: 15px ;
}

/* Pogu stili */
.custom-modal .modal-footer .btn {
  padding: 5px 10px; 
  border-radius: 8px; 
  font-size: 18px; 
  border: 1px solid transparent; 
  background-color: transparent; 
  transition: background-color 0.2s ease, border-color 0.2s ease, color 0.2s ease;
}

/* Atcelšanas poga */
.custom-modal .modal-footer .btn-secondary {
  color: #FFFFFF;
  font-weight: 400;
}

/* Atcelšanas poga hover efekts */
.custom-modal .modal-footer .btn-secondary:hover {
  background-color: #1D2A39; 
  border-color: #1D2A39;
}

/* Apstiprināšanas poga */
.custom-modal .modal-footer .btn-danger {
  color: #EC3942;
  font-weight: 700;
}

/* Apstiprināšanas poga hover efekts */
.custom-modal .modal-footer .btn-danger:hover {
  background-color: #1D2A39; 
  border-color: #1D2A39;
}

/* OK poga */
.custom-modal .modal-footer .btn-ok {
  color: #52B2F2;
  font-weight: 700;
}

/* OK poga hover efekts */
.custom-modal .modal-footer .btn-ok:hover {
  background-color: #1D2A39;
  border-color: #1D2A39;
}

/* Upgrade poga (pašlaik neaktīva) */
.custom-modal .modal-footer .btn-upgrade {
  color: #FFFFFF;
}

/* Upgrade poga hover efekts (ja to aktivizē nākotnē) */
.custom-modal .modal-footer .btn-upgrade:hover {
  background-color: #1D2A39;
  border-color: #1D2A39;
}
