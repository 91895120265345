:root {
  --background: #ffffff; 
  --background-100: #F3F4F6;
  --background-200: #E8E9EB;  
  --background-300: #D9D9DB;
  --background-dark: #5C5C5C;//
  --background-dark-300: #5C5C5C;
  --background-dark-400: #707070;
  --bgcolor: #1A1A1A;

  --background-light-200: #E8E9EB;
  --background-light-300: #D9D9DB;
  --background-ligth-400: #C5C5C7;

  --btn-hover: #0b5ed7;

  --background-light: #B1B1B2;
  --white: #ffffff;
  --text-dark-100: #24A1DE;
  --text-dark-200: #010203;
  --text-dark-300: #090303;

  --text-dark-500: #B3B3B3;

  --text-gray-100: #858585;
  --text-light-100: #ffffff;  
  --field-light: #ffffff;
  --button-bg: #090303; 
  --button-hover: #3B82F6; 
  --border-color: #ced4da; 
  --shadow-color: rgba(0, 0, 0, 0.1); 
  --bg-gray: #E8E9EB;
  --iconas: #B1B1B2;

  --bgdark: #5D5D5D;
  --danger: #F44336;
  --success: #498A47;
  --success-light: #6ABF6C;
  --telegram-blue: #24A1DE;
}

[data-theme="dark"] {
  --background: #121212;
  --background-100: #1A1A1A;
  --background-200: #2E2E2E;
  --background-300: #5C5C5C;
  --background-dark-300: #D9D9DB;
  --background-dark-400: #C5C5C7;
  --bgcolor: #FFFFFF;

  --background-light-200: #2E2E2E;
  --background-light-300: #5C5C5C;
  --background-ligth-400: #707070;

  --btn-hover: #0b5ed7;

  --background-light: #858585;
  --white: #ffffff;
  --text-dark-100: #24A1DE; 
  --text-dark-200: #f7f7f7;
  --text-dark-300: #ffffff; 

  --text-dark-500: #BABABA;
  --text-gray-100: #858585;
  --text-light-100: #000000; 
  --field-light: #121212;
  --button-bg: #ffffff; 
  --button-hover: #3b5998; 
  --border-color: #495057; 
  --shadow-color: rgba(0, 0, 0, 0.3); 
  --bg-gray: #2E2E2E;
  --iconas: #858585;
  --bgdark: #5D5D5D;
  --danger: #F44336;
  --success: #498A47;
  --success-light: #6ABF6C;
  --telegram-blue: #24A1DE;
}
$icon-size: 17px;
$padding-variable: 4px;